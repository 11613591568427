<template>
  <section class="investorDetails">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <div class="container">
      <el-table :data="tableData" stripe style="width: 100%" @row-click="handleRowClick">
        <el-table-column prop="code" label="股票代码" :width="width" align="center"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <!-- <el-table-column prop="link" :width="width" align="center">
          <template slot-scope="scope">
            <el-image style="width: 30px;" :src="scope.link || imgSrc" fit="cover"></el-image>
          </template>
        </el-table-column> -->
        <el-table-column prop="time" label="日期" :width="width" align="center"></el-table-column>
      </el-table>
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination> -->
    </div>
  </section>
</template>

<script>
import imageBanner from '../../components/ImageBanner.vue'
export default {
  components: {imageBanner,},
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/投资者关系.png'),
        slogan: require('../../assets/images/2023/investor/slogan.png'),
        class: 'investorSlogan',
      },
      tableData: null,
      width: '240',
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      let id = (this.$route.query.id).toString()
      let data = this.$store.getters.getInvestor && JSON.parse(JSON.stringify(this.$store.getters.getInvestor))
      console.log(id)
      switch (id) {
        case '1':
          this.tableData = data
          break
        case '2':
          this.tableData = data.filter(item => item.classify == 'temporary')
          break
        case '3':
          this.tableData = data.filter(item => item.classify == 'regular')
          break
        case '4':
        this.tableData = data.filter(item => item.classify == 'broker')
          break
        default:
          this.tableData = data
          break
      }
    },
    handleRowClick (row) {
      console.log(row)
      window.open(row.src)
    }
  }
}
</script>

<style>
.investorDetails {background-color: #ecf0f5; padding-bottom: 1rem;}
</style>